












































































import { Vue, Component, Watch } from "vue-property-decorator";
import api from "@/api";
import { PairingRecordDetailHistoryDto, SelectListItem, StudentDonorDto, StudentDonorType } from "@/api/appService";

@Component({
  name: "DonorDetail",
  components: {}
})
export default class DonorDetail extends Vue {
  donorInfo: StudentDonorDto = {
    name: "",
    type: "" as StudentDonorType | undefined,
    contactName: "",
    named: "",
    contactPhone: "",
    contactEmailAddress: "",
    address: "",
    remark: ""
  };
  StudentDonorTypeList: SelectListItem[] = [];
  labelPosition = "top";
  donorId = 0;
  pairingRecordDetailHistory: PairingRecordDetailHistoryDto[] = [];
  loading = true;

  // eslint-disable-next-line getter-return
  get donorType() {
    if (this.donorInfo.type === "Personal") {
      return "个人";
    } else if (this.donorInfo.type === "Enterprise") {
      return "企业";
    }
  }

  async created() {
    this.donorInfo = await this.getDonorInfoById();
    this.donorId = this.donorInfo.id!;
    this.StudentDonorTypeList = await this.getStudentDonorTypeList();
    this.pairingRecordDetailHistory = await this.getDonorHistory();
    this.loading = false;
  }

  getDonorInfoById() {
    return api.donor.get({
      id: Number(this.$route.params.donorId)
    });
  }

  getStudentDonorTypeList() {
    return api.enumService.getValues({ typeName: "StudentDonorType" });
  }

  getDonorHistory() {
    return api.donor.getDonorAidHistory({ donorId: this.donorId });
  }

  back() {
    this.$router.go(-1);
  }
}
